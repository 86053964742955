a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar {
    border-bottom: solid 1px #EBEBEB;
}

.bottom-tab-nav {
    border-top: solid 1px #EBEBEB;
}

.nav-link,
.bottom-nav-link {
    color: #6c757d !important;
}

    .bottom-nav-link.active {
        color: #0c689a !important;
    }

.bottom-tab-label {
    font-size: 12px;
}
.nav-link-top {
    color: #6c757d !important;
    /*color: #fff !important;*/
}