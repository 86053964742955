/* Provide sufficient contrast against white background */

body {
    margin-bottom: 70px;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}
html {
    height: 100%;
}
body{
    height: 100%;
}
.disabled {
    cursor: not-allowed !important;
}

